<template>
    <video-player 
        v-if = "cover.video"
        ref="player"
        class="vjs-custom-skin"
        id="video_preview"/>
    <v-img v-else
        :height="250"
        :src = "cover.image"
        />
</template>

<script>
    export default {
        props: ["cover"],
        methods: {
            link(cover) {
                let link = cover.video.url + '?';
                link = link + cover.video.cookies.map(e => `${e.name.replace('CloudFront-','')}=${e.value}`).join('&')
                return link
            }
        },
        mounted() {
            if (this.cover.video) {
                setTimeout(() => {
                    const streamOption = {
                        src: this.link(this.cover),
                        preload: false,
                        isLoop: true,
                        playsinline: false,
                        crossOrigin: true,
                        type: 'application/x-mpegurl'
                    }
                    const ref = this.$refs["player"]
                    const player = ref.player;
                    player.reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
                    player.src(streamOption)
                    player.pause()
                    player.muted(true)
                    // player.play()
                }, 1000)
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>