<template>
<div>
    <div v-if="Object.keys(data).length">
        <v-layout row>
            <v-flex md3 xs12 class = "pa-6">
                <h4>Server</h4>
                <v-card class = "mt-2 mb-2">
                    <v-list>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-cpu-32-bit
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ data.server.cpuUsage }}%
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    CPU usage
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-memory
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ data.server.memoryUsage.usedMemMb }}Mb used
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ data.server.memoryUsage.totalMemMb }}Mb total
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
                <h4>Kurento</h4>
                <v-card class = "mt-2">
                    <v-list>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-cpu-32-bit
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ data.server.kurento.cpuUsage }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    CPU usage
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-memory
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ data.server.kurento.memoryUsage }}KiB
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Used Memory
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-quality-high
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-text-field v-model = "config.minBandwidth" label = "Min Bandwidth" suffix="kbps"/>
                                </v-list-item-title>
                                <v-list-item-title>
                                    <v-text-field v-model = "config.maxBandwidth" label = "Max Bandwidth" suffix="kbps"/>
                                </v-list-item-title>
                                <v-list-item-title>
                                    <v-text-field v-model = "config.minBitrate" label = "Min Bitrate" suffix="bps"/>
                                </v-list-item-title>
                                <v-list-item-title>
                                    <v-text-field v-model = "config.maxBitrate" label = "Max Bitrate" suffix="bps"/>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="saveConfig">
                                    <v-icon>
                                        mdi-content-save-outline
                                    </v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-flex>
            <v-flex xs12 md4 class = "pa-6 scrollable">
                <h4>
                    Rooms
                </h4>
                <v-card class = "mt-2 mb-2" v-for = "(room, index, key) in data.rooms" :key = "key" :class = "{
                    [room.status]: true
                }" @click = "open(room)">
                    
                    <cover
                        :cover="room.cover"
                        class="vjs-custom-skin"
                        id="video_preview">
                        </cover>
                    <v-card-title>
                        {{ room.name }}
                    </v-card-title>
                    <v-card-subtitle>
                        ID: {{ room.id }}
                    </v-card-subtitle>
                    <v-list>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-avatar
                                    :color="statusColor(room.status)"
                                    size="10"
                                    ></v-avatar>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ room.status }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-account-star-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ room.presenters || 0 }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Presenters
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-account-group-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ room.viewers || 0 }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Viewers
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-alpha-s-circle-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ room.sessions.length }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Sessions
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-timer-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ totalStreamTime(room) }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Total time streamed
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-calendar-check-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ formatDate(room.createdAt) }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Created At
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-calendar-remove-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ formatDate(room.validUntil) }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Valid until
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-flex>
            <v-flex xs12 md4 class = "pa-6 scrollable">
                <h4>
                    Connections
                </h4>
                
                <v-card class = "mt-2 mb-2"  >
                    <v-list v-if = "data.users.length">
                        <v-list-item  v-for = "(connection, index, key) in data.users" :key = "`user_${key}`">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <img
                                        :src="connection.user.image"
                                    >
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{connection.user.username}}, <small>ID: {{connection.user.id}}, {{connection.user.role}}</small>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{connection.user.connectionId}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-card-text v-else>
                        No active connections
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
    <div v-else>
        <v-layout align-center justify-center>
            <v-progress-circular indeterminate/>
        </v-layout>
    </div>
</div>
</template>

<script>
    import axios from 'axios'
    import { URL } from '../../utils/consts'
    import { getUser } from "../../utils/login";
    import Cover from '../../components/Cover';
    import moment from 'moment';
    export default {
        components: {
            'cover': Cover
        },
        data() {
            return {
                data: {},
                config: {
                    minBandwidth: 0,
                    maxBandwidth: 0,
                    minBitrate: 0,
                    maxBitrate: 0
                }
            }
        },
        methods: {
            statusColor(status) {
                if (status === 'ACTIVE') return '#0F0';
                if (status === 'WAITING') return '#00F';
                return '#F00';
            },
            totalStreamTime(room) {
                const seconds = [0, ...room.sessions.map(s => {
                    console.log(s);
                    if (!s.duration) {
                        return moment(moment.utc()).diff(s.start, 'seconds')
                    }
                    return parseInt(s.duration)
                })].reduce((all, session) => (all + session));
                console.log(seconds);
                const second = Math.floor(seconds % 60);
                const minutes = Math.floor((seconds / 60) % 60);
                const hours = Math.floor((seconds / 60 / 60) % 60);
                const days = Math.floor((seconds / 60 / 60 / 24) % 24);
                return [
                    ...[{
                        value: days,
                        label: 'd'
                    }, {
                        value: hours,
                        label: 'h'
                    }, {
                        value: minutes,
                        label: 'm'
                    }].filter(s => s.value)
                    ,
                    {
                        value: second,
                        label: 's'
                    }
                ]
                .map(s => `${s.value} ${s.label}`)
                .join(' ');
            },
            open(room) {
                this.$router.push(`/admin/room/${room.id}`)
            },
            async fetch() {
                const { data } = await axios.get(URL() + "rooms/admin", {
                    headers: {
                    Authorization: "Bearer " + getUser().token,
                    }
                })
                this.data = data;
                setTimeout(() => {
                    this.fetch()
                }, 1000);
            },
            async getConfig() {
                const { data } = await axios.get(URL() + "rooms/admin/config", {
                    headers: {
                    Authorization: "Bearer " + getUser().token,
                    }
                })
                this.config = data;
            },
            formatDate(date) {
                return moment(date).format('DD. MM. YYYY, HH:mm')
            },
            async saveConfig() {
                await axios.post(URL() + "rooms/admin/config", this.config, {
                    headers: {
                    Authorization: "Bearer " + getUser().token,
                    }
                })
            }
        },
        created() {
            this.fetch()
            this.getConfig()
        }
    }
</script>

<style lang="scss" scoped>
    .ACTIVE { 
        border-left: 2px solid #0F0;
    }
    .WAITING { 
        border-left: 2px solid #00F;
    }
    .IDLE {
        border-left: 2px solid #F00;
    }
    
    .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
    
    #video_preview {
        width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: center;
    }
    .scrollable {
        max-height: 100vh;
        overflow: auto;
    }
</style>